// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"01a1202b7c39ec9443f0b34bc0dffeacd7eee7aa"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import { captureConsoleIntegration } from "@sentry/nextjs";

Sentry.init({
  dsn: "https://84f987c0c57448399893b5194c9ca87e@o447592.ingest.us.sentry.io/5898437",

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: (samplingContext) => {
    if (samplingContext.request?.headers?.["User-Agent"]?.toLowerCase()?.includes("checkly"))
      return 0;
    if (samplingContext.transactionContext.name.startsWith("trpc/")) return 0.05;
    return 0.1;
  },

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    captureConsoleIntegration({
      levels: process.env.NODE_ENV !== "development" ? ["assert"] : ["warn", "error", "assert"],
    }),
  ],
});
