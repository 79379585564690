import(/* webpackMode: "eager" */ "/vercel/path0/apps/box-office/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/auth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InvalidateTrpcOnAuthStateChange"] */ "/vercel/path0/apps/box-office/src/lib/providers/auth/invalidate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/emotion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/firebase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/messages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/posthog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/swr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/vercel/path0/apps/box-office/src/trpc/react.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+carousel@7.15.2_@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+reac_6x3jky2mugwai37oqzzkvnknk4/node_modules/@mantine/carousel/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+charts@7.15.2_@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+react@_2jskvv7rustcvprlxze4e4hle4/node_modules/@mantine/charts/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Center"] */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+react@19.0.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/esm/components/Center/Center.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Loader"] */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+react@19.0.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/esm/components/Loader/Loader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+react@19.0.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+react@19.0.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+dates@7.15.2_@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+react@1_f56fbwt2tyktr4tezn25lrstey/node_modules/@mantine/dates/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+dropzone@7.15.2_@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+reac_kb7ztjkawuc2yk3kgdals7dxlu/node_modules/@mantine/dropzone/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+notifications@7.15.2_@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types_z5zfjsjplvcb27473jwtd6xu2e/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+nprogress@7.15.2_@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+rea_o32irysx2qnrcfewlngfcryq6m/node_modules/@mantine/nprogress/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+spotlight@7.15.2_@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+rea_g7rw2oqirrqhbs2sqgbvcgw5oa/node_modules/@mantine/spotlight/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+tiptap@7.15.2_@mantine+core@7.15.2_@mantine+hooks@7.15.2_react@19.0.0__@types+react@_zusb72g3plos24pmkyn6jevt3e/node_modules/@mantine/tiptap/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.1_next@15.1.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+t_nbhhk74d6ni5qfpdxmpibogrny/node_modules/@vercel/analytics/dist/react/index.mjs");
